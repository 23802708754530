import { Link } from "gatsby"
import React from "react"
import css from "./ChapterThumb.module.scss"
import GatsbyImage from "gatsby-image"
export default function ChapterThumb(prop) {
  const capter = prop.capter
  const title = prop.title
  const childImageSharp = prop.childImageSharp
  const src = prop.src
  const href = prop.href

  // console.log(childImageSharp)

  let elFigure = (
    <figure>
      <figcaption>
        <span className={css.chapter}>{capter}</span>
        <span className={css.title}>{title}</span>
      </figcaption>
      {!childImageSharp && src && (
        <div className={css.imgBox}>
          <img src={src} alt="" />
        </div>
      )}
      {childImageSharp && (
        <div className={css.imgBox}>
          <GatsbyImage fluid={childImageSharp.fluid} alt="" />
        </div>
      )}
    </figure>
  )

  let elContent = (
    <Link to={href} aria-label={`動画のチャプター${css.chapter}にリンクします`}>
      {elFigure}
    </Link>
  )

  if (prop.disabled) {
    elContent = (
      <span aria-label={`動画のチャプター${css.chapter}にリンクします`}>
        {elFigure}
      </span>
    )
  }

  return (
    <div className={css.base}>
      {elContent}
      {prop.children && <p className={css.links}>{prop.children}</p>}
    </div>
  )
}
