import React from "react"
import css from "./Portrait.module.scss"
export default function Portrait(prop) {
  const src = prop.src
  const title = prop.title

  return (
    <div className={css.base}>
      <figure>
        <img src={src} alt={title} />
      </figure>
    </div>
  )
}
