import React from "react"
import GlobalNavi from "../components/GlobalNavi"
import BpHr from "../components/BpHr"
import LinkButton from "../components/LinkButton"
import Caption from "../components/Caption"
import Portrait from "../components/Portrait"
import ChapterThumb from "../components/ChapterThumb"
import ColorMenuButton from "../components/ColorMenuButton"
import Box from "@material-ui/core/Box"

export default function LayoutTest() {
  return (
    <div>
      <GlobalNavi />

      <div className={`l-wrapper`}>
        <h2 className={`fontLvH2`}>
          ブックプロジェクト Web サイトデザインルール
        </h2>
        <h3 className={`fontLvH2--s`}>ロゴの仕様</h3>
        <p className={`fontLvBody`}>
          以下 3 タイプのロゴを組み合わせて仕様します。
          回転は文字が逆さまにならない範囲で自由とします。 C
          のロゴのみ組み合わせで使用することを前提とします。組み合わせ時はなんとなく重なる感じ、で組み合わせます。
        </p>
        <Box mb={{ xs: 5, sm: 10 }}>
          <h4 className={`fontLvH3`}>ロゴ A</h4>
          <p>
            <img src="https://placehold.jp/980x300.png" alt="" />
          </p>
        </Box>

        <Box mb={5}>
          <h4 className={`fontLvH3`}>ロゴ B</h4>
          <p>
            <img src="https://placehold.jp/980x300.png" alt="" />
          </p>
        </Box>

        <Box mb={5}>
          <h4 className={`fontLvH3`}>ロゴ C</h4>
          <p>
            <img src="https://placehold.jp/980x300.png" alt="" />
          </p>
        </Box>

        <h3 className={`fontLvH2--s`}>文字サイズ定義</h3>
        <div className={`l-wrapper__inner`}>
          <h4 className={`fontLvH3`}>Midashi H2</h4>
          <p className={`fontLvH2`}>
            ダミーテキスト、ダミーテキスト、ダミーテキスト、ダミーテキスト、ダミーテキスト、ダミーテキスト、ダミーテキスト、ダミーテキスト、ダミーテキスト、ダミーテキスト、ダミーテキスト、ダミーテキスト、ダミーテキスト
          </p>
        </div>

        <div className={`l-wrapper__inner`}>
          <h4 className={`fontLvH3`}>Midashi H2 Sサイズ</h4>
          <p className={`fontLvH2--s`}>
            ダミーテキスト、ダミーテキスト、ダミーテキスト、ダミーテキスト、ダミーテキスト、ダミーテキスト、ダミーテキスト、ダミーテキスト、ダミーテキスト、ダミーテキスト、ダミーテキスト、ダミーテキスト、ダミーテキスト
          </p>
        </div>

        <div className={`l-wrapper__inner`}>
          <h4 className={`fontLvH3`}>Midashi H3</h4>
          <p className={`fontLvH3`}>
            ダミーテキスト、ダミーテキスト、ダミーテキスト、ダミーテキスト、ダミーテキスト、ダミーテキスト、ダミーテキスト、ダミーテキスト、ダミーテキスト、ダミーテキスト、ダミーテキスト、ダミーテキスト、ダミーテキスト
          </p>
        </div>
        <div className={`l-wrapper__inner`}>
          <h4 className={`fontLvH3`}>Body L</h4>
          <p className={`fontLvBodyL`}>
            ダミーテキスト、ダミーテキスト、ダミーテキスト、ダミーテキスト、ダミーテキスト、ダミーテキスト、ダミーテキスト、ダミーテキスト、ダミーテキスト、ダミーテキスト、ダミーテキスト、ダミーテキスト、ダミーテキスト
          </p>
        </div>
        <div className={`l-wrapper__inner`}>
          <h4 className={`fontLvH3`}>Body</h4>
          <p className={`fontLvBody`}>
            ダミーテキスト、ダミーテキスト、ダミーテキスト、ダミーテキスト、ダミーテキスト、ダミーテキスト、ダミーテキスト、ダミーテキスト、ダミーテキスト、ダミーテキスト、ダミーテキスト、ダミーテキスト、ダミーテキスト
          </p>
        </div>
        <div className={`l-wrapper__inner`}>
          <h4 className={`fontLvH3`}>Body S</h4>
          <p className={`fontLvBodyS`}>
            ダミーテキスト、ダミーテキスト、ダミーテキスト、ダミーテキスト、ダミーテキスト、ダミーテキスト、ダミーテキスト、ダミーテキスト、ダミーテキスト、ダミーテキスト、ダミーテキスト、ダミーテキスト、ダミーテキスト
          </p>
        </div>

        <div className={`l-wrapper__inner`}>
          <h4 className={`fontLvH2--s`}>区切り線</h4>
          <BpHr type={`dot`} />
          <BpHr type={`line`} />
        </div>

        <div className={`l-wrapper__inner`}>
          <h4 className={`fontLvH2--s`}>コンポーネント - Buttons</h4>
          <LinkButton color={`invert`}>ボタンコンポーネント</LinkButton>
          <LinkButton>ボタンコンポーネント</LinkButton>
        </div>

        <div className={`l-wrapper__inner--wide`}>
          <h4 className={`fontLvH2--s`}>コンポーネント - キャプション</h4>
          <Caption
            before={`作品`}
            medium={`パフォーマンス映像、シングルチャンネル／8分56秒`}
          >
            作品タイトル
          </Caption>
        </div>

        <div className={`l-wrapper__inner`}>
          <h4 className={`fontLvH2--s`}>コンポーネント - ポートレート</h4>
          <Portrait src="https://placehold.jp/300x300.png" title={`山田太郎`} />
          <Portrait src="https://placehold.jp/300x300.png" title={`山田太郎`} />
          <Portrait src="https://placehold.jp/300x300.png" title={`山田太郎`} />
          <Portrait src="https://placehold.jp/300x300.png" title={`山田太郎`} />
        </div>

        <div className={`l-wrapper__inner`}>
          <div className={`l-column`}>
            <div className={`l-column__50`}>
              <ChapterThumb
                capter={`Chapter 1`}
                title={`イントロダクション`}
                src={`https://placehold.jp/1600x900.png`}
              />
            </div>
            <div className={`l-column__50`}>
              <ChapterThumb
                capter={`Chapter 1`}
                title={`イントロダクション`}
                src={`https://placehold.jp/1600x900.png`}
              />
            </div>
          </div>
        </div>

        <div className={`l-wrapper__inner`}>
          <Box mt={5} mb={5}>
            <ColorMenuButton to={""} color={`red`}>
              <p className={`fontLvBodyL`}>
                <span className={`fontLvBody`}>作品:</span> 山本高之
              </p>
              <p className={`fontLvBodyL`}>
                <span className={`fontLvBody`}>応答するひと:</span> 松本美枝子 /
                中川美枝子
              </p>
            </ColorMenuButton>

            <ColorMenuButton to={""} color={`green`}>
              <p className={`fontLvBodyL`}>
                <span className={`fontLvBody`}>作品:</span> 山本高之
              </p>
              <p className={`fontLvBodyL`}>
                <span className={`fontLvBody`}>応答するひと:</span> 松本美枝子 /
                中川美枝子
              </p>
            </ColorMenuButton>

            <ColorMenuButton to={""} color={`black`}>
              <p className={`fontLvBodyL`}>
                <span className={`fontLvBody`}>作品:</span> 山本高之
              </p>
              <p className={`fontLvBodyL`}>
                <span className={`fontLvBody`}>応答するひと:</span> 松本美枝子 /
                中川美枝子
              </p>
            </ColorMenuButton>

            <ColorMenuButton to={""} color={`white`}>
              <p className={`fontLvBodyL`}>
                <span className={`fontLvBody`}>作品:</span> 山本高之
              </p>
              <p className={`fontLvBodyL`}>
                <span className={`fontLvBody`}>応答するひと:</span> 松本美枝子 /
                中川美枝子
              </p>
            </ColorMenuButton>

            <ColorMenuButton to={""} color={`yellow`}>
              <p className={`fontLvBodyL`}>
                <span className={`fontLvBody`}>作品:</span> 山本高之
              </p>
              <p className={`fontLvBodyL`}>
                <span className={`fontLvBody`}>応答するひと:</span> 松本美枝子 /
                中川美枝子
              </p>
            </ColorMenuButton>
          </Box>
        </div>
      </div>
    </div>
  )
}
