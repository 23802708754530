import React from "react"
import css from "./Caption.module.scss"
export default function Caption(prop) {
  const colorClass = prop.color ? css[prop.color] : ""

  return (
    <div className={css.base + ` ` + colorClass}>
      <div className={css.caption}>
        <span className={css.before}>{prop.before}</span>
        {prop.children}
      </div>
      <div className={css.medium}>{prop.medium}</div>
    </div>
  )
}
